import './NotFoundPage.scss';
import img from './PartyBeans.svg';
import { Image } from '../../components/Image';

export function NotFoundPage() {
  return (
    <div className="not-found-page">
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <h1>We can't find the page you're looking for</h1>
      <p>Hope you enjoy these partying beans though!</p>
      <Image alt="party beans" src={img} />
    </div>
  );
}
