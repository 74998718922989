// Direct React component imports
// eslint-disable-next-line import/no-unresolved, import/extensions
import { Swiper, SwiperSlide } from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
import { Box, Typography } from '@mui/material';
import { EventEntity } from '../../model';
import { Image } from '../Image';

export interface EventProps {
  eventEntity: EventEntity;
}

export function Event({ eventEntity }: EventProps) {
  const { country, date, drinks, food, images, title } = eventEntity;

  // We can make Food/Drink components if we ever add more than
  // just the name field
  const foodNames: string = food.map((f) => f.name).join(', ');
  const drinkNames: string = drinks.map((f) => f.name).join(', ');

  const d: Date = new Date(date);
  return (
    <Box display="flex" alignItems="center" justifyContent="center" flexDirection="column">
      <Typography variant="h3" sx={{ fontFamily: 'Blocked' }}>
        {title}
      </Typography>
      <Typography variant="h6">{country}</Typography>
      <Typography variant="h6">{d.toLocaleDateString()}</Typography>
      <Box key={title} width="50%" display="flex" alignItems="center" justifyContent="center">
        <Swiper spaceBetween={10} slidesPerView={1} initialSlide={0} centeredSlides zoom>
          {images?.map((imageUrl) => (
            <SwiperSlide>
              <Image alt={title} src={imageUrl} style={{ height: '25vh' }} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
      <Typography>{`Food: ${foodNames}`}</Typography>
      <Typography>{`Drinks: ${drinkNames}`}</Typography>
    </Box>
  );
}
