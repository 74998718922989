import { LocalizationProvider } from '@mui/lab';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import DateAdapter from '@mui/lab/AdapterDayjs';
import { MenuContainer } from './components/Menu/MenuContainer';
import { EventsPage } from './pages/EventsPage';
import { LandingPage } from './pages/LandingPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { SuggestMoviePage } from './pages/SuggestMoviePage';

import { PATHS } from './paths';

function App() {
  return (
    <div className="App">
      <LocalizationProvider dateAdapter={DateAdapter}>
        <BrowserRouter>
          <MenuContainer />
          <Routes>
            <Route path={PATHS.home} element={<LandingPage />} />
            <Route path={PATHS.events} element={<EventsPage />} />
            <Route path={PATHS.suggest} element={<SuggestMoviePage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </div>
  );
}

export default App;
