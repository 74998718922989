import { useEffect, useState } from 'react';

import { eventsAPI } from '../../apis/events';
import { EventEntity } from '../../model';

export const useGetEvents = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<EventEntity[]>();
  const [error, setError] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await eventsAPI.fetchEvents();
      setData(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    loading,
    error,
    data,
  };
};
