/* eslint-disable no-console */
import { Send } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { DatePicker, Input, Button } from '../../ui';
import { SuggestMovieFormValues } from './SuggestMovieForm.types';
import './SuggestMovieForm.scss';

export function SuggestMovieForm() {
  const { register, control, handleSubmit, getValues } = useForm<SuggestMovieFormValues>();

  // TODO: send this somewhere
  const submit = () => {
    console.log('Form values');
    console.log(getValues());
    handleSubmit(console.log);
  };

  return (
    <form className="suggest-movie-form">
      <Stack spacing={2} maxWidth={800}>
        <Typography variant="h3" fontWeight={500} fontSize={50} fontFamily="Blocked" marginBottom={5}>
          Suggest a Movie
        </Typography>
        <Input register={register} name="name" label="Movie Name" placeholder="Beans the Movie" />
        <Input register={register} name="country" label="Country" placeholder="Beantown, USA" />
        <DatePicker control={control} name="date" label="Date" orientation="landscape" />
        <Box className="suggest-movie-form__two-wide">
          <Input register={register} name="food" label="Food" placeholder="Can o' Beans" />
          <Input register={register} name="drinks" label="Drinks" placeholder="Bean Water" />
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button endIcon={<Send />} variant="outlined" onClick={submit}>
            Next
          </Button>
        </Box>
      </Stack>
    </form>
  );
}
