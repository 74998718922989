import { Box, CircularProgress, Stack } from '@mui/material';
import { useGetEvents } from '.';
import { Error } from '../../components/Error';
import { Event } from '../../components/Event';
import './EventsPage.scss';

export function EventsPage() {
  const { loading, error, data } = useGetEvents();

  if (error) {
    return <Error />;
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (!data) {
    return <Box>No events to show!</Box>;
  }

  return (
    <div>
      <Stack spacing={5}>
        {data.map((event) => (
          <Event key={event.title} eventEntity={event} />
        ))}
      </Stack>
    </div>
  );
}
