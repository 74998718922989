import { Controller, Path, Control } from 'react-hook-form';
import { DesktopDatePicker as MUIDatePicker, DesktopDatePickerProps } from '@mui/lab';
import { TextField } from '@mui/material';
import './DatePicker.scss';

interface DatePickerProps<T> {
  name: Path<T>;
  label: string;
  placeholder?: string;
  control: Control<T>;
  required?: boolean;
  orientation: DesktopDatePickerProps['orientation'];
}

export default function DatePicker<T>({ name, control, orientation, label, required = false }: DatePickerProps<T>) {
  return (
    <Controller
      rules={{ required }}
      name={name}
      control={control}
      render={({ field }) => (
        <div className="form-datepicker">
          <p className="form-input__label">{label}</p>
          <MUIDatePicker
            {...field}
            InputProps={{ sx: { fontFamily: 'Scribble', fontSize: '1.5rem' } }}
            orientation={orientation}
            renderInput={(params) => <TextField className="datepicker-div" {...params} />}
          />
        </div>
      )}
    />
  );
}
