import React from 'react';
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { HomeOutlined, AccessTime, Lightbulb } from '@mui/icons-material';
import { MenuProps } from './Menu';
import './Menu.scss';

export function CollapsedMenu({ goHome, goToEvents, goToSuggestMovie }: Omit<MenuProps, 'isSmallScreen'>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHome = () => {
    goHome();
    handleClose();
  };

  const handlePastEvents = () => {
    goToEvents();
    handleClose();
  };

  const handleSuggestMovie = () => {
    goToSuggestMovie();
    handleClose();
  };

  return (
    <div className="menu">
      <Button
        id="collapsed-menu-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MenuIcon color="action" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'collapsed-menu-button',
        }}
      >
        <MenuItem onClick={handleHome}>
          <ListItemIcon>
            <HomeOutlined />
          </ListItemIcon>
          <ListItemText>
            <p style={{ fontFamily: 'Blocked', fontSize: '1.5rem' }}>Home</p>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handlePastEvents}>
          <ListItemIcon>
            <AccessTime />
          </ListItemIcon>
          <ListItemText>
            <p style={{ fontFamily: 'Blocked', fontSize: '1.5rem' }}>Past Events</p>
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handleSuggestMovie}>
          <ListItemIcon>
            <Lightbulb />
          </ListItemIcon>
          <ListItemText>
            <p style={{ fontFamily: 'Blocked', fontSize: '1.5rem' }}>Suggest Movie</p>
          </ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
