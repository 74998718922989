import { CollapsedMenu } from './CollapsedMenu';
import './Menu.scss';

export interface MenuProps {
  goHome(): void;
  goToEvents(): void;
  goToSuggestMovie(): void;
  isSmallScreen?: boolean;
}

export function Menu({ goHome, goToEvents, goToSuggestMovie, isSmallScreen = false }: MenuProps) {
  if (isSmallScreen) {
    return <CollapsedMenu goHome={goHome} goToEvents={goToEvents} goToSuggestMovie={goToSuggestMovie} />;
  }

  return (
    <div className="menu">
      <div className="menu__section">
        <button type="button" className="menu__item" onClick={goHome}>
          SPOOKY SUNDAYS
        </button>
      </div>

      <div className="menu__right-items">
        <div className="menu__section">
          <button type="button" className="menu__item" onClick={goToEvents}>
            Past Events
          </button>
        </div>

        <div className="menu__section">
          <button type="button" className="menu__item" onClick={goToSuggestMovie}>
            Suggest Movie
          </button>
        </div>
      </div>
    </div>
  );
}
