import { Container } from '@mui/material';
import { SuggestMovieForm } from '../../components/SuggestMovieForm';

export function SuggestMoviePage() {
  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <SuggestMovieForm />
    </Container>
  );
}
