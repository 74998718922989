import React, { useState } from 'react';
import cx from 'classnames';
import './Image.scss';
/**
 * Image provides a way to avoid a jump
 * as an image loads
 */
export type ImageProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export function Image({ ...rest }: ImageProps) {
  const [loaded, setLoaded] = useState(false);

  const handleLoad = () => setLoaded(true);

  return (
    <img
      {...rest}
      className={cx(rest.className, 'image', { 'image--loaded': loaded })}
      alt={rest.alt}
      onLoad={handleLoad}
    />
  );
}
