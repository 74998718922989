/* eslint-disable react/jsx-pascal-case */
import { Box } from '@mui/material';
import { Image } from '../../components/Image';
import { _React17HTMLRenderHelper } from '../../serviceWorker';
import './LandingPage.scss';
import logo from './spookyLogo.svg';

export function LandingPage() {
  return (
    <Box>
      <_React17HTMLRenderHelper />
      <Image alt="logo" src={logo} className="landing-page__logo" />
    </Box>
  );
}
