import { Button, Popover } from '@mui/material';
import React, { useState } from 'react';
import sw from './fonts/sw.jpg';
import { Image } from './components/Image';

// eslint-disable-next-line no-underscore-dangle
export function _React17HTMLRenderHelper() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        style={{
          boxShadow: 'none',
          background: 'transparent',
          width: 40,
          height: 40,
          position: 'absolute',
          top: 100,
          left: 100,
          cursor: 'default',
        }}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Image src={sw} />
      </Popover>
    </div>
  );
}
