import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../paths';
import { Menu } from './Menu';

export function MenuContainer() {
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const goHome = () => navigate(PATHS.home);
  const goToEvents = () => navigate(PATHS.events);
  const goToSuggestMovie = () => navigate(PATHS.suggest);

  return (
    <Menu goHome={goHome} goToEvents={goToEvents} goToSuggestMovie={goToSuggestMovie} isSmallScreen={isSmallScreen} />
  );
}
