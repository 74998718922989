import axios, { AxiosResponse } from 'axios';
import { DrinkEntity, EventEntity, FoodEntity } from '../../model';

const instance = axios.create({
  baseURL: 'https://www.spookysundays.tv/public/events2.json',
  timeout: 15000,
});

interface EventResponse {
  events: EventEntity[];
}

const responseBody = (response: AxiosResponse) => response.data;

const fetchEvents = async (): Promise<EventEntity[]> => {
  try {
    const eventResponse = await instance.get<EventResponse>('');
    return responseBody(eventResponse).events;
  } catch (e) {
    if (e instanceof Error) {
      console.error(e.message);
    }
    throw e;
  }
};

export const eventsAPI = {
  fetchEvents,
};
