import { Path, UseFormRegister } from 'react-hook-form';
import './Input.scss';

interface InputProps<T> {
  name: Path<T>;
  label?: string;
  placeholder?: string;
  register: UseFormRegister<T>;
  required?: boolean;
}

export default function Input<T>({ name, register, label, placeholder, required = false }: InputProps<T>) {
  return (
    <div className="form-input">
      <p className="form-input__label">{label}</p>
      <input
        className="form-input__input"
        aria-label={label}
        placeholder={placeholder}
        {...register(name, { required })}
      />
    </div>
  );
}
